import styled from '@emotion/styled';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH } from 'constants/sizes';

import { Layout, NewsCard, GatsbyPicture } from 'UI';

export const Container = styled(Layout)`
  grid-column: 1/-1;
  gap: 2rem;
  align-items: start;

  grid-template-areas:
    'a a b b b b b b b b'
    'a a c c c c c c c c';

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-template-areas:
      'a a a a a a'
      'b b b b b b'
      'c c c c c c';
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    row-gap: 40px;
    grid-template-areas:
      'a a a a'
      'b b b b'
      'c c c c';
  }
`;

export const Wrapper = styled(Layout)`
  grid-area: b;
  grid-template-columns: repeat(8, minmax(0, 1fr));

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

export const Card = styled(NewsCard)`
  grid-column: span 4;
  grid-row: span 1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: span 3;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const ShowMore = styled.button`
  grid-row: span 1;
  grid-area: c;
  cursor: pointer;
  justify-self: start;
  padding: 0;
`;

export const Picture = styled(GatsbyPicture)`
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 66.6%;
  display: block;

  img {
    width: 100%;
    position: absolute;
    height: 100%;
    object-fit: cover;
  }
`;
