import React, { FC, useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IPressRelease } from 'interfaces/press';
import { MoreText } from 'UI';

import {
  Container, Card, ShowMore, Wrapper, Picture,
} from './PressReleases.styled';

const query = graphql`
  query {
    allStrapiPressReleases(
      sort: {order: DESC, fields: date}
      filter: {article: {article: {ne: null}}, isFuture: {eq: false}}
    ) {
      nodes {
        title
        header
        slug
        id: strapiId
        media {
          ...MediaImageFragment
        }
        file {
          localFile {
            publicURL
          }
          alternativeText
        }
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;

interface IPressReleasesQuery {
  allStrapiPressReleases: {
    nodes: IPressRelease[];
  }
}

interface IPressReleasesComponent {
  nodes: IPressRelease[];
}

const MAX_ITEMS = 4;

const PressReleases: FC<IPressReleasesComponent> = ({ nodes }) => {
  const [maxShown, setMaxShown] = useState<number>(MAX_ITEMS);

  const handleOpen = () => setMaxShown(nodes.length);

  const handleClose = () => setMaxShown(MAX_ITEMS);

  return (
    <Container withPaddings>
      <Wrapper gridOnly>
        {nodes.map(({
          title, id, date, media, slug,
        }, index) => index < maxShown && (
          <Card
            key={id}
            title={title}
            date={date}
            link={`/press-releases/${slug}`}
          >
            <Picture {...media[0]} />
          </Card>
        ))}
      </Wrapper>

      {nodes.length > MAX_ITEMS && (
        <ShowMore type="button" onClick={maxShown === MAX_ITEMS ? handleOpen : handleClose}>
          <MoreText active={maxShown > MAX_ITEMS}>
            {maxShown > MAX_ITEMS ? 'Show less' : 'Show more'}
          </MoreText>
        </ShowMore>
      )}
    </Container>
  );
};

const PressReleasesQuery: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IPressReleasesQuery) => {
      if (!data?.allStrapiPressReleases?.nodes?.length) return null;
      const { nodes } = data.allStrapiPressReleases;

      return (
        <PressReleases nodes={nodes} />
      );
    }}
  />
);

export default PressReleasesQuery;
