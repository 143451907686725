import styled from '@emotion/styled';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH } from 'constants/sizes';

export const Header = styled.h2`
  grid-column: 1/-1;
  margin: 4rem 0 0.5em;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin: 3rem 0;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin: 1rem 0 0;
  }
`;
