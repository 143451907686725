import React, { FC } from 'react';

import { Layout } from 'UI';

import { Header } from './Title.styled';

interface ITitle {
  text: string;
}

const Title: FC<ITitle> = ({ text }) => (
  <Layout>
    <Header>{text}</Header>
  </Layout>
);

export default Title;
